import React, { Component } from 'react';
import { addDays, format } from 'date-fns';
import { Row, Col, Radio, Card, Typography, DatePicker } from 'antd';
import '../../css/global.css';
import Barra from '../../components/graficos/Barra';
//import Hora from './Hora';

import coresGraficos from '../../Config/cores';
//import { Link } from 'react-router-dom';
import api from '../../services/api';

export default class Atendente extends Component {
    constructor(props) {
        super(props);
        this.state = {
            valorTeste: [],
            dataInicioFormatada: '',
            dataFimFormatada: '',
            //checked: true,       
            atendimentoPorAtendente: {
                chatData: {
                    labels: ['serviços'],
                    datasets: [
                        {
                            label: 'Protocolo',
                            fontSize: 12,
                            data: [0],
                            borderWidth: 1,
                            backgroundColor: [
                                coresGraficos.preenchimento[0],
                            ],
                            borderColor: [
                                coresGraficos.linha[0],
                            ]
                        }, {
                            label: 'Certidão',
                            fontSize: 12,
                            data: [0],
                            borderWidth: 1,
                            backgroundColor: [
                                coresGraficos.preenchimento[1],
                            ],
                            borderColor: [
                                coresGraficos.linha[1],
                            ]
                        }
                    ],

                },
                options: {
                    legend: {
                        display: false,
                        position: "bottom",
                    },
                    scales: {
                        yAxes: [{
                            type: 'linear',
                            ticks: {
                                beginAtZero: true
                            },
                            gridLines: {
                                drawOnChartArea: true,
                            }
                        }]
                    },
                }

            },
            tempoAtendimentoPorAtendente: {
                chatData: {
                    labels: [],
                    datasets: [
                        {
                            label: 'Protocolo',
                            fontSize: 12,
                            data: [
                            ],
                            borderWidth: 1,
                            backgroundColor: [
                                coresGraficos.preenchimento[0],
                                coresGraficos.preenchimento[0],
                            ],
                            borderColor: [
                                coresGraficos.linha[0],
                                coresGraficos.linha[0],
                            ]
                        }, {
                            label: 'Certidão',
                            fontSize: 12,
                            data:  [
                            ],
                            borderWidth: 1,
                            backgroundColor: [
                                coresGraficos.preenchimento[1],
                                coresGraficos.preenchimento[1],
                            ],
                            borderColor: [
                                coresGraficos.linha[1],
                                coresGraficos.linha[1],
                            ]
                        }
                    ],

                },
                options: {
                    legend: {
                        display: true,
                        position: 'top',
                    },
                    scaleOverride: true,
                    responsive: true,
                    scales: {
                        yAxes: [{
                          ticks: {
                            callback: function (value, index, values) {
                              return new Date(value * 1000).toISOString().substr(12, 7);
                            }
                          }
                        }]
                      },
                      tooltips: {
                        callbacks: {
                          label: function (tooltipItem, data) {
                            return 'Tempo: ' + new Date(tooltipItem.yLabel * 1000).toISOString().substr(12, 7)
                          }
                        }
                      }
                }

            },
        }
    }

    formataData(state, data) {
        const dd = data.split("-");
        const dataPronta = dd[2] + '/' + dd[1] + '/' + dd[0];
        //const dataFormatada = format(data, 'dd/MM/YYY');
        this.setState({ [state]: dataPronta });
        //console.log(dd);
    }

    formataDataPeriodo(data) {
        const dd = data.split("/");
        const dataPronta = dd[2] + '-' + dd[1] + '-' + dd[0];
        return dataPronta;
    }

    formataDataFiltro() {
        const dataHoje = new Date();
        const data = format(dataHoje, 'YYY-MM-dd');
        return data;
    }

    async componentDidMount() {
        this.filtraDia();
    }

    filtraDia = tipo => {
        const dataFim = this.formataDataFiltro();
        this.enviaFiltragem(dataFim, dataFim);
        this.formataData('dataInicioFormatada', dataFim);
        this.formataData('dataFimFormatada', dataFim);
    }

    filtraSemana = async tipo => {
        const dataHoje = new Date();
        const dataFim = format(dataHoje, 'YYY-MM-dd');
        const dataConvertida = addDays(dataHoje, -6);
        const dataInicio = format(dataConvertida, 'YYY-MM-dd');
        this.enviaFiltragem(dataInicio, dataFim);
        this.formataData('dataInicioFormatada', dataInicio);
        this.formataData('dataFimFormatada', dataFim);
        //console.log(dataInicio);
    }

    filtraMes = async (mes) => {
        const dataHoje = new Date();
        const dataFim = format(dataHoje, 'YYY-MM-dd');
        const dataConvertida = addDays(dataHoje, -30);
        const dataInicio = format(dataConvertida, 'YYY-MM-dd');
        this.enviaFiltragem(dataInicio, dataFim);
        this.formataData('dataInicioFormatada', dataInicio);
        this.formataData('dataFimFormatada', dataFim);
    }

    filtraPeriodo = (value, dateString) => {
        const dataHoje = new Date();
        const data = format(dataHoje, 'dd/MM/YYY');
        const inicio = dateString[0] ? dateString[0] : data;
        const fim = dateString[1] ? dateString[1] : data;
        const dataInicio = this.formataDataPeriodo(inicio);
        const dataFim = this.formataDataPeriodo(fim);

        //console.log("onchange", inicio, fim);

        this.enviaFiltragem(dataInicio, dataFim);
        this.setState({ dataInicioFormatada: inicio });
        this.setState({ dataFimFormatada: fim });
    }

    converteTempo(s) {

        function duas_casas(numero) {
            if (numero <= 9) {
                numero = "0" + numero;
            }
            return numero;
        }

        const hora = duas_casas(Math.round(s / 3600));
        const minuto = duas_casas(Math.floor((s % 3600) / 60));
        const segundo = duas_casas((s % 3600) % 60);

        const formatado = hora + ":" + minuto + ":" + segundo;

        return (formatado);
    }

    enviaFiltragem = async (dataInicio, dataFim) => {

        const ip = localStorage.getItem('MG_IP');            

        //gera state senhas atendidas
        const atendentes = await api.get(`/atendimentos/atendimentosPorAtendente/${dataInicio}/${dataFim}/${ip}`);
        const dadosAtendentes = atendentes.data;

        const atendentesDados = await api.get(`/atendimentos/listaAtendentes/${dataInicio}/${dataFim}/${ip}`);
        const nomeAtendentes = atendentesDados.data;
        
        //atendentes
        const nomeAtendente = nomeAtendentes.map((item) => item.nome);

        //agrupa serviços
        const servicos = dadosAtendentes.map((item) => item.servico);
        const eliminaServicos = servicos.filter((item, i) => servicos.indexOf(item) === i);


        const dados = [];
        const dadosTempo = [];

        for (let i = 0; i <= nomeAtendentes.length; i++) {
            const servicos = eliminaServicos[i];
            if (servicos !== undefined) {
                const qtdServicos = await api.get(`/atendimentos/listaQtdServico/${servicos}/${dataInicio}/${dataFim}/${ip}`);
                //gera state tempo médio de atendimento
                const tempoAtendentes = await api.get(`/atendimentos/tempoMedioAtendimentosPorAtendente/${servicos}/${dataInicio}/${dataFim}/${ip}`);
        
                //dados
                const dataQtd = Object.values(qtdServicos.data);
                const qtd = dataQtd.map(i => { return (Object.values(i)) });              
                const qtdMerge = qtd.join();
                const qtdNumber = qtdMerge.split(',').map(item => parseInt(item, 10));

                //dadosTempo
                const dataQtdTempo = Object.values(tempoAtendentes.data);
                const qtdTempo = dataQtdTempo.map(i => { return (Object.values(i)) });              
                const qtdTempoMerge = qtdTempo.join();
                const qtdTempoNumber = qtdTempoMerge.split(',').map(item => parseInt(item, 10));                
                
                //console.log(qtdTempoNumber);

                //cores do gráfico
                const background = dataQtd.map((item, t) => { return (coresGraficos.preenchimento[i]) });
                const linhas = dataQtd.map((item, t) => { return (coresGraficos.linha[i]) });

                //console.log("cores",background);

                dados.push({
                    label: servicos,
                    fontSize: 12,
                    data: qtdNumber,
                    borderWidth: 1,
                    backgroundColor:
                        background
                    ,
                    borderColor:
                        linhas

                });

                dadosTempo.push({
                    label: servicos,
                    fontSize: 12,
                    data: qtdTempoNumber,
                    borderWidth: 1,
                    backgroundColor:
                        background
                    ,
                    borderColor:
                        linhas

                });
            }

        }
        //console.log("state",this.state.atendimentoPorAtendente);
        //console.log("montagem",dados);

        //atualiza estado dos atendentes
        const labelsAtendente = Object.assign({}, this.state.atendimentoPorAtendente);
        labelsAtendente.chatData.labels = nomeAtendente;

        const dadosMontadosAtendentes = Object.assign({}, this.state.atendimentoPorAtendente);
        dadosMontadosAtendentes.chatData.datasets = dados;

        const labelsTempoAtendente = Object.assign({}, this.state.tempoAtendimentoPorAtendente);
        labelsTempoAtendente.chatData.labels = nomeAtendente;

        const dadosMontadosTempoAtendentes = Object.assign({}, this.state.tempoAtendimentoPorAtendente);
        dadosMontadosTempoAtendentes.chatData.datasets = dadosTempo;

        //atualiza o state
        this.setState({ labelsAtendente, dadosMontadosAtendentes,dadosMontadosTempoAtendentes });
    }

    render() {
        const { RangePicker } = DatePicker;
        const { Title } = Typography;
        //const { totalSenhas } = this.state;

        return (
            <div>
                <Row gutter={24} type="flex" justify="space-between">
                    <Col lg={11}>
                        <Title style={{ fontWeight: 'bold' }} level={2}>Atendentes</Title>
                    </Col>
                    <Col md={12} lg={12} xl={12} >
                        <Title style={{ textAlign: 'right' }} level={4}>{`Período: ${this.state.dataInicioFormatada} a ${this.state.dataFimFormatada}`}</Title>
                    </Col>
                </Row>
                <Row gutter={24} type="flex" justify="end">
                    <Col md={12} lg={12} xl={12} style={{ marginBottom: '10px', textAlign: 'right' }}>
                        <RangePicker
                            placeholder={['Período...']}
                            //onOk={this.onOk}
                            onChange={this.filtraPeriodo}
                            //defaultValue={[moment(dataHoje, 'DD/MM/YYYY'), moment(dataHoje, 'DD/MM/YYYY')]}
                            format={'DD/MM/YYYY'}
                        />{'  '}
                        <Radio.Group value={14} >
                            <Radio.Button checked={this.state.checked} onClick={() => this.filtraDia()} value="large">HOJE</Radio.Button>
                            <Radio.Button onClick={() => this.filtraSemana()} value="default">SEMANA</Radio.Button>
                            <Radio.Button onClick={() => this.filtraMes()} value="small">MÊS</Radio.Button>
                        </Radio.Group>
                    </Col>
                </Row>

                <Row gutter={24} style={{ marginBottom: '15px' }}>
                    <Col style={{ marginBottom: '20px' }} md={24} lg={24} xl={24}>
                        <Card size="small" title={<div><b>SENHAS ATENDIDAS POR ATENDENTE</b></div>}>
                            <Col md={24} lg={24} xl={24}>
                                <Barra width={100} height={localStorage.getItem('heightGraphic')}  periodo={this.state.atendimentoPorAtendente.chatData} options={this.state.options} />
                            </Col>
                        </Card>
                    </Col>
                </Row>

                <Row gutter={24} style={{ marginBottom: '15px' }}>
                    <Col style={{ marginBottom: '20px' }} md={24} lg={24} xl={24}>
                        <Card size="small" title={<div><b>TEMPO MÉDIO DE ATENDIMENTO POR ATENDENTE</b></div>}>
                            <Col md={24} lg={24} xl={24}>
                                <Barra width={100} height={parseInt(localStorage.getItem('heightGraphic'))}  periodo={this.state.tempoAtendimentoPorAtendente.chatData} options={this.state.tempoAtendimentoPorAtendente.options} />
                            </Col>
                        </Card>
                    </Col>
                </Row>

            </div>
        );
    }
}
