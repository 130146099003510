//import jwtDecode from 'jwt-decode';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const TOKEN_COOKIE_NAME = 'mgtoken';
const URL_SRI_COOKIE_NAME = 'mgurlsri';
const TOKEN_SRI_COOKIE_NAME = 'mgtokensri';

export const saveToken = (token) => {
    cookies.set(TOKEN_COOKIE_NAME, token);
}

export const getToken = () => {
    return cookies.get(TOKEN_COOKIE_NAME);
}

export const removeToken = () => {
    return cookies.remove(TOKEN_COOKIE_NAME);
}

export const saveUrlSri = (url) => {
    cookies.set(URL_SRI_COOKIE_NAME, url);
}

export const getUrlSri = () => {
    return cookies.get(URL_SRI_COOKIE_NAME);
}

export const removeUrlSri = () => {
    return cookies.remove(URL_SRI_COOKIE_NAME);
}

export const saveTokenSri = (url) => {
    cookies.set(TOKEN_SRI_COOKIE_NAME, url);
}

export const getTokenSri = () => {
    return cookies.get(TOKEN_SRI_COOKIE_NAME);
}

export const removeTokenSri = () => {
    return cookies.remove(TOKEN_SRI_COOKIE_NAME);
}


export const isLoggedIn = () => {
    return !!getToken();
}

// export const getUsuario = () => {
//     return jwtDecode(getToken());
// }