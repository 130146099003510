import React from 'react';
import { Pie } from 'react-chartjs-2';

function Pizza (props) { 
        return (                           
                <Pie
                    data={props.periodo}
                    width={props.width}
                    height={props.height}
                    options={props.options}
                />            

        );
    }

export default Pizza;
