import React, { useState, useEffect } from 'react';
import JwtDecode from 'jwt-decode';
import axios from 'axios';
import api from '../../services/apiLogin';
// import {apiSri} from '../../services/apiSri';
import { Form, Input } from '@rocketseat/unform';
import FormData from 'form-data'
import '../../css/global.css';
//import apiPhp from '../../services/apiPhp';
//import api from '../../services/api';
import * as Yup from 'yup';
import history from '../../services/history';
import { saveToken, getToken, saveUrlSri, getUrlSri,saveTokenSri} from '../../utils/LoginManager';

const schema = Yup.object().shape({
  email: Yup.string().required('Insira um login válido'),
  senha: Yup.string().required('Digite sua senha'),
});

const Login = (props) => {
  const [mensagem, setMensagem] = useState("");


  function handleSubmit({ email, senha }) {
    verificaLogin(email, senha);
  }

  async function verificaLogin(email, senha) {
    const bodyFormData = new FormData();
    const config = { headers: { 'Content-Type': 'multipart/form-data' } }
    bodyFormData.append('email', email)
    bodyFormData.append('senha', senha)
    console.log(process.env.REACT_APP_PHP)
    const usuario = await axios.post(process.env.REACT_APP_PHP+'/APILogin.php', bodyFormData, config).then(
      (response) => {
        console.log(response)
        return response
      }
    ).catch(
      (error) => {
        console.log(error)
        return error
      }
    )
    const dados = usuario.data;
    if (dados.erro === true) {
      setMensagem(dados.mensagem);
    } else {
      saveToken(dados.token);
      const token = getToken();
      const configura = JwtDecode(dados.token);
      const configuracao = JSON.parse(configura.dadosAdicionais);
      console.log(configuracao)
      localStorage.setItem('MG_IP', configuracao[0].ip);
      
      localStorage.setItem('MG_CLIENTE', dados.nome);
    
    await saveUrlSri(configuracao[0].apisri)

    var dadosLogin;
    if(process.env.NODE_ENV=='development'){
     dadosLogin = {usuario:"m",senha:"1"};
    }else{
      //if(configuracao[0].cns=="85886"){
//        dadosLogin = {usuario:"mactus",senha:"macinfo"};
  //    }else{
        dadosLogin = {usuario:"mactus",senha:"codes207017"}
    //  };
    }
     let sriUrl = getUrlSri()
     try{
      const tokenSri = await axios.post(`${sriUrl}/auth`,dadosLogin); 
      if(tokenSri){
       // const {data:tk } = tokenSri.data;
       //console.log(tk.token);      
        console.log(tokenSri)
        // localStorage.setItem('MG_TOKEN_SRI',tokenSri.data.data.token);
        saveTokenSri(tokenSri.data.data.token)
      }
    }catch(err){
      console.log("API SRI ERROR: "+err)
    }
      if (token) {
        setMensagem(dados.mensagem);
        history.push('/dashboard');       
      }
    }
  }

  return (
    <Form schema={schema} className="form-login" onSubmit={handleSubmit}>
      <img className="login-logo" src="../../mactus-branca.png" alt="" />
      <div className="form-login-mensagem">{mensagem}</div>
      <Input className="input-login" maxLength={50} name="email" type="text" placeholder="E-Mail..." />
      <Input className="input-login" maxLength={40} name="senha" type="password" placeholder="Senha..." />
      <button className="button-login" type="submit"  >Acessar</button>
    </Form>
  );
}

export default Login;

