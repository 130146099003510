import axios from 'axios';
import { getUrlSri, getTokenSri} from '../utils/LoginManager';

let URL = getUrlSri();

let tokenSri= getTokenSri();

    
export const apiSri = axios.create({    
    baseURL: URL
});

export const apiSriHeaders = { headers: {
    'Authorization': `Bearer ${tokenSri}`,
}};


