import React, { Component, useState, useEffect } from 'react';
import  axios from 'axios';
import { apiSri, apiSriHeaders } from "../../services/apiSri";
import { Row, Col, Card, DatePicker, Typography, Table } from 'antd';
import Pizza from '../../components/graficos/Pizza';
import Linha from '../../components/graficos/Linha';
import BarraHorizontal from '../../components/graficos/BarraHorizontal';
import coresGraficos from '../../Config/cores'
import moment from "moment"
import {StringMes} from '../../components/MesConverter';
import { getUrlSri, getTokenSri} from '../../utils/LoginManager';
import Loader from "../../components/Loader"
const coresGrafico =      [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
        'rgba(79,79,79,1)',
        'rgba(72,61,139,1)',
        'rgba(0,0,205,1)',
        'rgba(30,144,255,1)',
        'rgba(173,216,230,1)',
        'rgba(119,136,153,1)',
        'rgba(199,21,133,1)',
        'rgba(205,92,92,1)',
        'rgba(250,128,114,1)',
        'rgba(216,191,216,1)',
        'rgba(139,69,19,1)',
        'rgba(238,232,170,1)',
        'rgba(128,0,0,1)',
        'rgba(165,42,42,1)',
        'rgba(238,130,238,1)',
        'rgba(123,104,238,1)',
        'rgba(50,205,50,1)',
        'rgba(0,250,154,1)',
        'rgba(204,39,39,0.8)',
        'rgba(128,128,0,1)',
        'rgba(0,0,0,1)',
        'rgba(230,238,156,1)',
        'rgba(229,115,115,1)',
        'rgba(136,14,79,1)',
        'rgba(128,24,24,50)',
        'rgba(13, 71,161,1)',
        'rgba(62,39,35,1)',
        'rgba(156,39,176,1)',
        'rgba(255,238,88,1)',
        'rgba(76,175,80,1)',
        'rgba(0,188,212,1)',
        'rgba(255,87,34,1)',
        'rgba(51, 102, 153,1)',
        'rgba(215, 189, 226,1)',
        'rgba(229,152,102,1)',
        'rgba(118,215,196,1)',
        'rgba(249, 231, 159,1)',
        'rgba(124, 79, 224,1)',
        'rgba(128,62,62,50)',
        'rgba(195,255,182,1)',
        'rgba(181,145,172,1)',
        'rgba(221,171,255,1)',
        'rgba(54,0,102,0.4)',
        'rgba(240,0,96,0.94)',
        'rgba(243,152,0,0.95)',

    ]



function Financeiro(props) {
    ///////////////////
    const dataAtualBruta = new Date()
    let dataAtualFormatada = `${dataAtualBruta.getDate() < 10 ? `0${dataAtualBruta.getDate() - 1}` : dataAtualBruta.getDate() - 1}/${dataAtualBruta.getMonth() + 1}/${dataAtualBruta.getFullYear()}`
    var arrayData = dataAtualFormatada.split("/");
    
    

    
    var textoData = `${arrayData[0]} de ${StringMes(Number(arrayData[1]))} de ${arrayData[2]}`
    
    //////////////////////
    const[URL_SRI,setURL_SRI] = useState(getUrlSri());
    const[SRI_HEADER,setSRI_HEADER] = useState({
        headers:{
       'Authorization': `Bearer ${getTokenSri()}`
       }
     });

    const [chartData, setChartData] = useState({});
    const [chartData2, setChartData2] = useState({});
    const [colorChart, setColorChart]=useState([]);
    
    const [opChartData, setOpChartData] = useState({
        legend: {
            display: false,
            position: "top",
        },

        title: {
            display: false,
            fontSize: 16,
            fontFamily: 'Helvetica, Arial, sans-serif',
            text: "",
            position: 'top',
            border: '0',
        },
        //responsive: true
        maintainAspectRatio: false

    })
    const [datePicker, setDatePicker] = useState(dataAtualFormatada)
    const [dataTxt, setDataTxt] = useState(textoData)
    const { Title } = Typography;
    const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY', 'YYYY/MM/DD'];
    const [qtdeTotal, setQtdeTotal] = useState(0);
    const [credito, setCredito] = useState(0);
    const [debito, setDebito] = useState(0);
    const [valorTotal, setValorTotal] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    
    function getChartData(movimentos) {
        //descMD, qtdeMD, valorMD,qtdeTotalMD,valorTotalMD
        var descMD = movimentos.map( m=> m.tipo)
        var valorMD = movimentos.map( m=> Number(m.valor.toFixed(2)))
      
        var qtdeMD = movimentos.map( m=> m.contador)

        var valorTotalMD = valorMD.reduce(function (totalPronto, quantidade) {
                return totalPronto + quantidade;
            }, 0).toFixed(2)

        var creditoMD = 0;
        valorMD.map(
            (m)=>{
                if(m>=0){
                   
                    creditoMD += Number(m.toFixed(2));
                }
            }
        )

        var debitoMD = 0;
        valorMD.map(
            (m)=>{
                if(m<0){  
                    debitoMD += Number(m.toFixed(2));
                }
            }
        )
        
        
        var qtdeTotalMD = qtdeMD.reduce(function (totalPronto, quantidade) {
            return totalPronto + quantidade;
        }, 0);

        console.log(descMD,qtdeMD, valorMD,qtdeTotalMD,valorTotalMD)

        setChartData({
            labels: descMD,
            datasets: [{
                // label: 'Quantidade',
                data: qtdeMD,
                backgroundColor: coresGrafico,
                borderColor:coresGrafico,
                borderWidth: 1
            }]
        });
        setChartData2({
            labels: descMD,
            datasets: [{
                // label: 'Quantidade',
                data: valorMD,
                backgroundColor: coresGrafico,
                borderColor:coresGrafico,
                borderWidth: 2
            }]
        })
        setQtdeTotal(qtdeTotalMD)
        setCredito(Number(creditoMD).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }))
        setDebito(Number(debitoMD).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }))
        setValorTotal(Number(valorTotalMD).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }))
        setIsLoading(false)
    };
        



    useEffect(() => {
        getData(1, `${dataAtualFormatada}`)
    }, []);


    function getData(value, dateString) {
        if (dateString == "") {
            
            return null
        }
        
        setDatePicker(dateString)

        const dd = dateString.split("/");
        const dataPronta =`${dd[2]}-${dd[1].length>1?dd[1]:"0"+dd[1]}-${dd[0]}`;
    
        fazRequisicaoMD(dataPronta)

       

    
        setDataTxt(`${dd[0]} de ${ StringMes(Number(dd[1]))} de ${dd[2]}`)

    }



    async function fazRequisicaoMD(data) {
        setIsLoading(true)
        const dadosMD = await axios.get(`${URL_SRI}/api/sri/financeiro/movimento?filtroDia={"dia":"${data}T00:00:00"}`,  
       SRI_HEADER).then().catch(
            (err) => {
                console.log('Erro requisição: ' + err)
                return null
            }
        )
        let auxDadosMD = []
        
        var movimentos = []

        dadosMD.data.caixaFinanceiros.forEach(caixaFinanceiro => {

            var movimento = {
                "tipo": caixaFinanceiro.descricao?caixaFinanceiro.descricao:caixaFinanceiro.tipofinanceiro,
                "valor":caixaFinanceiro.valor,
                "contador": 1
            }
            var movimentoFind = movimentos.find(m => m.tipo == movimento.tipo);
            

            if(movimentoFind){
                movimentoFind.valor = movimentoFind.valor + movimento.valor
                movimentoFind.contador = movimentoFind.contador + movimento.contador
            }else{
                movimentos.push(movimento)
            }
        });  
          
            
          
        getChartData(movimentos)
    }


    const data = [
        {
            key: '1',
            name: 'John Brown',
            age: 32,
            address: 'New York No. 1 Lake Park',
        },
        {
            key: '2',
            name: 'Joe Black',
            age: 42,
            address: 'London No. 1 Lake Park',
        },
        {
            key: '3',
            name: 'Jim Green',
            age: 32,
            address: 'Sidney No. 1 Lake Park',
        },
        {
            key: '4',
            name: 'Jim Red',
            age: 32,
            address: 'London No. 2 Lake Park',
        },
    ];

    const columns = [
        {
            title: 'Número',
            dataIndex: 'numero',
            key: 'numero',
            width: '10%',
            // ...this.getColumnSearchProps('numero'),
        },
        {
            title: 'Valor',
            dataIndex: 'valor',
            key: 'valor',
            width: '10%',
            //...this.getColumnSearchProps('valor'),
        },
        {
            title: 'Descrição',
            dataIndex: 'descricao',
            key: 'descricao',
            // ...this.getColumnSearchProps('descricao'),
        },
        {
            title: 'Forma de Pagamento',
            dataIndex: 'pagamento',
            key: 'pagamento',
            //...this.getColumnSearchProps('pagamento'),
        },
    ];



    return (
        <div>
            <Title style={{display:"flex"}}>FINANCEIRO
                <div style={{width:"5%"}}></div>
                {isLoading ? <Loader style={{display: "flex"}}/>: ""}
            </Title>
            
            <Row>
                <Col md={24} lg={24} xl={24}>
                    <Card style={{ backgroundColor: "#f9f9f9f9" }}
                        title={
                            <Row type="flex" justify="space-between">
                                <h2 style={{ textAlign: "left" }}>Movimento Diário: <DatePicker onChange={getData} defaultValue={moment(dataAtualFormatada, dateFormatList[0])} format={dateFormatList} style={{ width: "120px" }} /> </h2>
                                <h2 style={{ textAlign: "right" }}> {dataTxt} </h2>
                            </Row>
                        }>
                        <Row type="flex" justify="space-between">
                            <div style={{ width: "210px", marginRight: "5px" }}> <Title style={{
                                fontWeight: 'bold', color: "#ffffff", backgroundColor: coresGraficos.linha[2], padding: "5px", borderRadius: "12px",
                                fontSize: "auto", textAlign: "center"
                            }} level={4}>QUANTIDADE:<br/> {qtdeTotal}</Title></div>

                            <div style={{ width: "210px" }}> <Title style={{
                                fontWeight: 'bold', color: "#ffffff", backgroundColor: coresGraficos.linha[3], padding: "5px", borderRadius: "12px",
                                fontSize: "auto", textAlign: "center"
                            }} level={4}>ENTRADA: <br/> {credito}</Title></div>

                            <div style={{ width: "210px" }}> <Title style={{
                                fontWeight: 'bold', color: "#ffffff", backgroundColor: coresGraficos.linha[6], padding: "5px", borderRadius: "12px",
                                fontSize: "auto", textAlign: "center"
                            }} level={4}>SAÍDA: <br/> {debito}</Title></div>

                            <div style={{ width: "210px" }}> <Title style={{
                                fontWeight: 'bold', color: "#ffffff", backgroundColor: credito>=debito?coresGraficos.linha[3]:coresGraficos.linha[6], padding: "5px", borderRadius: "12px",
                                fontSize: "auto", textAlign: "center"
                            }} level={4}>SALDO:<br/> {valorTotal}</Title></div>
                        </Row>
                        <Row type="flex" justify="space-between">
                            <Col md={24} lg={24} xl={12}>
                                <Pizza periodo={chartData} options={opChartData} height={"500px"}/>
                            </Col>
                            <Col md={24} lg={24} xl={12}>
                                <BarraHorizontal periodo={chartData2} options={opChartData} height={"500px"}/>
                            </Col>
                        </Row>


                    </Card>
                </Col>

            </Row>
            {/* <Row>
                <Col md={24} lg={24} xl={24}>

                    < Table bordered={true} columns={columns} dataSource={data} style={{ marginTop: "10px" }} />
                </Col>
            </Row> */}

        </div>

    )



}


export default Financeiro