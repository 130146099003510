export const NumMes = (mesString) => {
    var mes
    var nmrMes
    switch (mesString) {
      case "Janeiro":
        mes = "01"
        nmrMes = 0
        break;
      case "Fevereiro":
        mes = "02"
        nmrMes = 1
        break;
      case "Março":
        mes = "03"
        nmrMes = 2
        break;
      case "Abril":
        mes = "04"
        nmrMes = 3
        break;
      case "Maio":
        mes = "05"
        nmrMes = 4
        break;
      case "Junho":
        mes = "06"
        nmrMes = 5
        break;
      case "Julho":
        mes = "07"
        nmrMes = 6
        break;
      case "Agosto":
        mes = "08"
        nmrMes = 7
        break;
      case "Setembro":
        mes = "09"
        nmrMes = 8
        break;
      case "Outubro":
        mes = "10"
        nmrMes = 9
        break;
      case "Novembro":
        mes = "11"
        nmrMes = 10
        break;
      case "Dezembro":
        mes = "12"
        nmrMes = 11
        break;

      default:
        mes = "asdasdasd"
        mes = "1"
        nmrMes = 1
        break;
    }

    return nmrMes

}

export const StringMes = (mesNum)=>{

    var stringMes;
    
    switch (`${mesNum}`) {
        case "1":
            stringMes = "Janeiro"
          
          break;
        case "2":
            stringMes = "Fevereiro"
          
          break;
        case "3":
            stringMes = "Março"
          
          break;
        case "4":
            stringMes = "Abril"
          
          break;
        case "5":
            stringMes = "Maio"
          
          break;
        case"6":
            stringMes = "Junho"
         
          break;
        case "7":
            stringMes = "Julho"
         
          break;
        case "8":
            stringMes = "Agosto"
          
          break;
        case "9":
            stringMes = "Setembro"
         
          break;
        case "10":
            stringMes = "Outubro"
        
          break;
        case "11":
            stringMes = "Novembro"
          
          break;
        case "12":
            stringMes = "Dezembro"
    
          break;
  
        default:
            stringMes = "________"
          
          break;
      }
  return stringMes;

}