import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

function Loader(props) {
    return (
        <CircularProgress />
    );
}

export default Loader;
