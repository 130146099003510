import React, { Component } from 'react'
import { Row, Col, Radio, Card, DatePicker, Typography } from 'antd';
import { addDays, format } from 'date-fns';
import Loader from "../../components/Loader"
import Linha from '../../components/graficos/Linha';
import Pizza from "../../components/graficos/Pizza"
import coresGraficos from '../../Config/cores';
import axios from "axios";
import {apiSri,apiSriHeaders} from "../../services/apiSri";
import { saveToken, getToken } from '../../utils/LoginManager';

export default class Pedidos extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            dataInicioFormatada: '',
            dataFimFormatada: '',
            totalPedidos: { dados: [0, 0, 0] },
            pedidos: {

            },
            options: {

                legend: {
                    display: true,
                    position: "top",
                },

                title: {
                    display: true,
                    fontSize: 16,
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    text: "Gráfico Geral",
                    position: 'top',
                    border: '1',
                },

            },



        }


    }
    getChartData(totalProtocolo, totalCertidao, totalExameCalculo) {

        if (totalProtocolo == 0 && totalCertidao == 0 && totalExameCalculo == 0) {

            this.setState({

                totalPedidos: { dados: [totalProtocolo, totalCertidao, totalExameCalculo] },
                pedidos: {
                    labels: [
                        'Protocolos',
                        'Certidões',
                        'Exame de Calculo',
                    ],
                    datasets: [
                        {
                            labels: ["Tipo Pedido"],
                            data: [0, 0, 0, 100],
                            backgroundColor: [coresGraficos.linha[0], coresGraficos.linha[1], coresGraficos.linha[2], "#f9f9f9f9"],
                            borderColor: "#001529",
                            borderWidth: 1
                        }
                    ]


                }

            })
        } else {
            this.setState({
                totalPedidos: { dados: [totalProtocolo, totalCertidao, totalExameCalculo] },
                pedidos: {
                    labels: [
                        'Protocolos',
                        'Certidões',
                        'Exame de Calculo',
                    ],
                    datasets: [
                        {
                            labels: ["Tipo Pedido"],
                            data: [totalProtocolo, totalCertidao, totalExameCalculo],
                            backgroundColor: [coresGraficos.linha[0], coresGraficos.linha[1], coresGraficos.linha[2]],
                            borderColor: "#001529",
                            borderWidth: 1
                        }
                    ]


                }

            })
        }





    }

    formataData(state, data) {

        const dd = data.split("-");

        const dataPronta = dd[2] + '/' + dd[1] + '/' + dd[0];
        //const dataFormatada = format(data, 'dd/MM/YYY');
        this.setState({ [state]: dataPronta });

    }
    formataDataPeriodo(data) {
        const dd = data.split("/");
        const dataPronta = dd[2] + '-' + dd[1] + '-' + dd[0];
        return dataPronta;
    }
    formataDataFiltro() {
        const dataHoje = new Date();
        const data = format(dataHoje, 'YYY-MM-dd');
        return data;
    }
    filtraDia = e => {
        const dataFim = this.formataDataFiltro();
        this.enviaFiltragem(dataFim, dataFim);
        this.formataData('dataInicioFormatada', dataFim);
        this.formataData('dataFimFormatada', dataFim);

    }
    filtraSemana = async e => {
        const dataHoje = new Date();
        const dataFim = format(dataHoje, 'YYY-MM-dd');
        const dataConvertida = addDays(dataHoje, -6);
        const dataInicio = format(dataConvertida, 'YYY-MM-dd');
        this.enviaFiltragem(dataInicio, dataFim);
        this.formataData('dataInicioFormatada', dataInicio);
        this.formataData('dataFimFormatada', dataFim);



    }
    filtraMes = async mes => {
        const dataHoje = new Date();
        const dataFim = format(dataHoje, 'YYY-MM-dd');
        const dataConvertida = addDays(dataHoje, -30);
        const dataInicio = format(dataConvertida, 'YYY-MM-dd');
        this.enviaFiltragem(dataInicio, dataFim);
        this.formataData('dataInicioFormatada', dataInicio);
        this.formataData('dataFimFormatada', dataFim);

    }

    filtraPeriodo = (value, dateString) => {
        const dataHoje = new Date();
        const data = format(dataHoje, 'dd/MM/YYY');
        const inicio = dateString[0] ? dateString[0] : data;
        const fim = dateString[1] ? dateString[1] : data;
        const dataInicio = this.formataDataPeriodo(inicio);
        const dataFim = this.formataDataPeriodo(fim);
        this.enviaFiltragem(dataInicio, dataFim);
        this.setState({ dataInicioFormatada: inicio });
        this.setState({ dataFimFormatada: fim });

    }

    async componentDidMount() {
        this.filtraDia();

    }

    enviaFiltragem = async (dataInicio, dataFim) => {
        const token = getToken();
        console.log(token)

        this.setState({ loading: true }); 
        
        const pedidosDados = await apiSri.get(`api/sri/relatorio/pedidos/dataPronto?filtro={"inicio":"${dataInicio}T17:34:59.522","fim":"${dataFim}T17:34:59.522","status"="PRONTO"}`,apiSriHeaders)
       
        const dadosPedidos = pedidosDados.data;

        // const dadosPedidos = await axios({
        //     method: 'get',
        //     url: `http://192.168.2.51:8080/api/sri/relatorio/pedidos/dataPronto?filtro={"inicio":"${dataInicio}T17:34:59.522","fim":"${dataFim}T17:34:59.522","status"="PRONTO"}`,
        //     headers: {
        //         'Authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJtIiwicm9sZSI6IlJPTEVfVVNVQVJJTyIsImNyZWF0ZWQiOnsibmFubyI6MzM2OTcyMDAwLCJ5ZWFyIjoyMDIwLCJtb250aFZhbHVlIjoxMSwiZGF5T2ZNb250aCI6MTYsImhvdXIiOjEyLCJtaW51dGUiOjE2LCJzZWNvbmQiOjUwLCJtb250aCI6Ik5PVkVNQkVSIiwiZGF5T2ZXZWVrIjoiTU9OREFZIiwiZGF5T2ZZZWFyIjozMjEsImNocm9ub2xvZ3kiOnsiaWQiOiJJU08iLCJjYWxlbmRhclR5cGUiOiJpc284NjAxIn19LCJleHAiOjE2MDYxMzM4MTB9.VPGMuiH_UALeGRU8GCMC9eMcyAb8cv7IXj5IXuo_x9TRvthkOcv3r6w6S8GA71ve7Ec5SKa9IQx9K6nwW7aEmw',
        //     },
        // }).then(function (response) {
        //     console.log(response.data)
        //     return response.data
        // })
        //     .catch(function (error) {

        //         console.log(error.message);

        //     });

        console.log(dadosPedidos);

        function trataDadosProtocolos(dadosPedidos) {
            let certidao = [];
            let protocolo = [];
            let exameCalculo = [];
            let totalCertidao;
            let totalProtocolo;
            let totalExameCalculo = [];
            let auxCadastros = []
            for (const dataObj of dadosPedidos) {
                auxCadastros.push((dataObj))

            }
            for (const dataPedido of auxCadastros) {
                if (dataPedido.tipoPedido == "PROTOCOLO") {
                    protocolo.push(dataPedido.portador)
                } else if (dataPedido.tipoPedido == "CERTIDAO") {
                    certidao.push(dataPedido.portador)
                } else if (dataPedido.tipoPedido == "EXAME_CALCULO") {
                    exameCalculo.push(dataPedido.portador)
                }

            }


            totalExameCalculo = exameCalculo.length
            totalProtocolo = protocolo.length
            totalCertidao = certidao.length

            return { totalProtocolo, totalCertidao, totalExameCalculo }

        }

        let pedidos = trataDadosProtocolos(dadosPedidos)
            


        
        console.log(pedidos.totalProtocolo, pedidos.totalCertidao, pedidos.totalExameCalculo)
        this.getChartData(pedidos.totalProtocolo, pedidos.totalCertidao, pedidos.totalExameCalculo)
        this.setState({ loading: false });

    }




    render() {
        const { RangePicker } = DatePicker;
        const { Title, Paragraph } = Typography;
        return (
            <div>

                <Row gutter={24} type="flex" justify="space-between">
                    <Col md={12} lg={12} xl={12}>
                        <Title style={{ fontWeight: "bold" }} level={2}>Pedidos</Title>
                    </Col>
                </Row>{'  '}
                <Row gutter={24} type="flex" justify="end">
                    <Col md={12} lg={12} xl={12} style={{ marginBottom: '10px', textAlign: 'right' }}>
                    <b>{`Período: ${this.state.dataInicioFormatada} a ${this.state.dataFimFormatada}`}</b>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col md={24}>
                        <Card size="small" style={{height: "auto", backgroundColor: "#f9f9f9" }}
                            title={
                                <Row type="flex" justify="space-between" style={{ marginTop: '20px' }}>
                                     <RangePicker
                                        placeholder={['Período...']}

                                        onChange={this.filtraPeriodo}
                                        format={'DD/MM/YYYY'}
                                        style={{width:"210px"}}
                                    />
                                    <h2>Registro de Certidões e Protocolos</h2>
                                        <Radio.Group value={14} >
                                            <Radio.Button onClick={() => this.filtraDia()} value="large">HOJE</Radio.Button>
                                            <Radio.Button onClick={() => this.filtraSemana()} value="default">SEMANA</Radio.Button>
                                            <Radio.Button onClick={() => this.filtraMes()} value="small">MÊS</Radio.Button>
                                        </Radio.Group>
                                    
                                </Row>}>
                            <Col style={{ marginTop: '10px' }} md={24} lg={24} xl={24}>
                                <div style={{

                                    alignItems: "center",
                                    display: "flex",
                                    flexDirection: "row",
                                    flexWrap: "wrap",
                                    justifyContent: "center",
                                }}>
                                    {this.state.loading ?
                                        <Loader />
                                        : null}
                                    <Pizza width={100} height={35}
                                        periodo={this.state.pedidos}
                                        options={this.state.options}
                                    />
                                </div>
                            </Col>
                            <Col style={{ marginTop: '10px', display: "flex" }} md={24} lg={24} xl={24}>
                                <Col md={4} lg={4} xl={4}>
                                    <Title style={{ color: coresGraficos.linha[0], marginTop: '0px', marginBottom: '0px', fontWeight: 'bold', fontSize: '18px' }}>{this.state.totalPedidos.dados[0]}</Title>
                                    <Paragraph style={{ marginBottom: '0px', fontSize: '14px', fontWeight: 'bold' }}>Protocolos Registrados</Paragraph>
                                </Col>
                                <Col md={4} lg={4} xl={4}>
                                    <Title style={{ color: coresGraficos.linha[1], marginTop: '0px', marginBottom: '0px', fontWeight: 'bold', fontSize: '18px' }}>{this.state.totalPedidos.dados[1]}</Title>
                                    <Paragraph style={{ marginBottom: '0px', fontSize: '14px', fontWeight: 'bold' }}>Certidões Prontas</Paragraph>
                                </Col>
                                <Col md={4} lg={4} xl={4}>
                                    <Title style={{ color: coresGraficos.linha[2], marginTop: '0px', marginBottom: '0px', fontWeight: 'bold', fontSize: '18px' }}>{this.state.totalPedidos.dados[2]}</Title>
                                    <Paragraph style={{ marginBottom: '0px', fontSize: '14px', fontWeight: 'bold' }}>Exames de Cálculo Registrados</Paragraph>
                                </Col>
                            </Col>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }
}