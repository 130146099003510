import axios from 'axios';

//if(process.env.NODE_ENV === 'development'){
    let URL = process.env.REACT_APP_PHP;   
// if(process.env.REACT_APP_AMBIENTE === 'development'){
//     URL = 'http://192.168.2.51:8083';
// }else{
//     URL = 'https://macfinanceiro.mactus.com.br';
// }

const apiLogin = axios.create({    
    baseURL:URL
});

export default apiLogin;