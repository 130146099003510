import React from 'react';
import { HorizontalBar } from 'react-chartjs-2';

function BarraHorizontal(props) {
    return (
        <HorizontalBar        
            data={props.periodo}
            width={props.width}
            height={props.height}
            options={props.options}
        />
    );
}

export default BarraHorizontal;
