import axios from 'axios';
    let URL = process.env.REACT_APP_SGA
    // if(process.env.NODE_ENV === 'development'){
    //      URL = "http://192.168.2.51:3001/api";   
    // }else{
    //     URL = 'https://gestao.mactus.online/api';
    // }

 const api = axios.create({    
    baseURL:URL
    
})

export default api;
