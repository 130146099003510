function mesAtual(){
    var mes
    var nmrMes
   const data =  new Date()
    switch (data.getMonth()) {
        case 0:    
        mes = "Janeiro" 
        nmrMes = 0
            break;
        case 1:    
        mes = "Fevereiro"    
        nmrMes = 1
            break;
        case 2:    
        mes = "Março"   
        nmrMes = 2 
            break;
        case 3:   
        mes = "Abril"    
        nmrMes = 3    
        break;
        case 4:    
        mes = "Maio"    
        nmrMes = 4
        break;
        case 5:    
        mes = "Junho"
        nmrMes = 5    
            break;
        case 6:    
        mes = "Julho"    
        nmrMes = 6    
        break;
        case 7:    
        mes = "Agosto"    
        nmrMes = 7
        break;
        case 8:    
        mes = "Setembro"
        nmrMes = 8    
            break;
        case 9:    
        mes = "Outubro"    
        nmrMes = 9
        break;
        case 10:    
        mes = "Novembro"    
        nmrMes = 10
        break;
        case 11:    
        mes = "Dezembro"    
        nmrMes = 11
        break;
       
        default:
            mes = "Janeiro"
            nmrMes = 0
            break;
    }
    var ano = data.getFullYear()
 
    var data2 =  new Date(ano,data.getMonth(),1)
    
  
    var dias = [];
    while (data2.getMonth() === data.getMonth()) {
        dias.push(data2.getDate());
        data2.setDate(data2.getDate() + 1);
    }

    return {mes, ano, nmrMes}
  }


  
  const dataDefault = mesAtual();

  
  export default dataDefault