import styled from 'styled-components';



export const divTotais = styled.div`
    color: red;
    padding: 5px;
    background-color: greenyellow;
    border-radius: 12px;
    margin-left: 5px;
    
`;