import React, { Component } from 'react';
import axios from "axios"
//import { apiSri, apiSriHeaders } from "../../../services/apiSri";
import { saveToken, getToken } from '../../utils/LoginManager';
import moment from "moment"
import { addDays, format } from 'date-fns';
import { Chart } from 'react-chartjs-2';
import { divTotais } from './styled';
import { Row, Col, Radio, Card, DatePicker, Typography, Form, Input, Button, Progress, Tabs } from 'antd';
//import 'moment/locale/pt-br';
import '../../css/global.css';
import Barra from '../../components/graficos/Barra';
import Linha from '../../components/graficos/Linha';
import Rosca from '../../components/graficos/Rosca';
import Pizza from '../../components/graficos/Pizza';
import coresGraficos from '../../Config/cores';
//import { Link } from 'react-router-dom';
import Loader from "../../components/Loader"
//import { If } from '../../../components/if';
import dataDefault from "../../utils/dataDefault"
import { Select } from 'antd';
import dataAtual from "../../utils/dataDefault"
import './style.css';
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import VisibilitySensor from "react-visibility-sensor";
// import BarraHorizontal from '../../../components/graficos/BarraHorizontal';
import { HorizontalBar } from 'react-chartjs-2';
import { getUrlSri, getTokenSri} from '../../utils/LoginManager';



const dataRosca ={
	labels: [
		'Red',
		'Green',
		'Yellow'
	],
	datasets: [{
		data: [300, 50, 100],
		backgroundColor: [
		'#FF6384',
		'#36A2EB',
		'#FFCE56'
		],
		hoverBackgroundColor: [
		'#FF6384',
		'#36A2EB',
		'#FFCE56'
		]
	}]
};

const { Option } = Select;
const headers = {
  'Authorization': `Bearer ${localStorage.getItem('MG_URL_SRI')}`
};
function timeout(delay) {
  return new Promise(res => setTimeout(res, delay));
}

export default class Protocolo extends Component {

  constructor(props) {
    super(props);
    this.state = {

      URL_SRI: '',
      TOKEN_SRI: '',
      SRI_HEADER: {},
      tipoGrafico: false,
      bgGrafico: "transparent",
      key: 'cadastrados',
      loading: false,
      mesQtde: "",
      anoQtde: "",
      dataInicio: '',
      dataFim: '',
      dataInicioFormatada: '',
      dataFimFormatada: '',
      totalProtocolos: { dados: [0, 0] },
      totalProtocolosMensal: { dados: [0, 0] },
      totalProtocolosProgressBar: { dados: [0, 0, 0] },
      //checked: true,
      protocolo: {
        chartData: {},
        chartDataPronto: {},

        options: {

          legend: {
            display: false,
            position: "bottom",
          },
          scales: {
            yAxes: [{
              type: 'linear',
              ticks: {
                beginAtZero: true
              },
              gridLines: {
                drawOnChartArea: true,
              }
            }]
          },
        }

      },
      protocoloMensal: {
        chartDataMensalLinha: {},
        chartDataMensalBarra: {},
        options: {
          title: {
            display: true,
            fontSize: 16,
            fontFamily: 'Helvetica, Arial, sans-serif',
            text: "",
            position: 'top',
            border: '1',
          },

          legend: {
            display: false,
            position: "bottom",
          },
          scales: {
            yAxes: [{
              type: 'linear',
              ticks: {
                beginAtZero: true
              },
              gridLines: {
                drawOnChartArea: true,
              }
            }]
          },
        }
      },
      protocoloTotal: {
        chartDataTotal: {
          text: "AAAAAAA",
          labels: ["A", "B"],
          datasets: [
            {
              label: 'TOTAL',
              data: [1, 2],
              backgroundColor: [coresGraficos.linha[0], coresGraficos.linha[1]],


              borderColor: [coresGraficos.linha[0], coresGraficos.linha[1]],


              borderWidth: 0
            },

          ],
        },
        options: {
          title: {
            display: true,
            fontSize: 16,
            fontFamily: 'Helvetica, Arial, sans-serif',
            text: "!!!!!!!!",
            position: 'top',
            border: '1',
          },

          legend: {
            display: false,
            position: "bottom",
          },
          scales: {
            yAxes: [{
              type: 'linear',
              ticks: {
                beginAtZero: true
              },
              gridLines: {
                drawOnChartArea: true,
              }
            }]
          },
        }
      },
      protocoloStatusTotal:{
        chartDataStatus:{},
        options: {

          legend: {
              display: true,
              position: "bottom",
          },

          title: {
              display: false,
              fontSize: 16,
              fontFamily: 'Helvetica, Arial, sans-serif',
              text: "Gráfico Geral",
              position: 'top',
              border: '1',
          },

      },
      },
      title: "Movimentação diária",
      contador: 0
    };


  }  

  async componentDidMount() {
    this.filtraMes();
    this.setState({URL_SRI:getUrlSri(),
                   TOKEN_SRI:getTokenSri(),
                   SRI_HEADER: {
                     headers:{
                    'Authorization': `Bearer ${getTokenSri()}`
                    }
                  },
                });
  }
  async componentWillMount(){
    this.contadorProgressivo()
  }

  getDiasMensalData(descricaoCadastroMensal, quantidadeCadastroMensal, totalCadastroMensal,
    quantidadeMensalPronto, totalProntoMensal) {
    this.setState({
      protocoloMensal: {
        chartDataMensalLinha: {

          labels: descricaoCadastroMensal,
          datasets: [
            {
              label: 'CADASTRADOS',
              data: quantidadeCadastroMensal,
              backgroundColor: 'transparent',

              backgroundColor: coresGraficos.preenchimento[0],
              borderColor: coresGraficos.linha[0],


              borderWidth: 4
            },
            {
              label: 'PRONTOS',
              data: quantidadeMensalPronto,
              backgroundColor: this.state.bgGrafico,
              lineTension: 0.2,
              backgroundColor: coresGraficos.preenchimento[1],
              borderColor: coresGraficos.linha[1],


              borderWidth: 4
            },
          ],


        },
        chartDataMensalBarra: {

          labels: descricaoCadastroMensal,
          datasets: [
            {
              label: 'Cadastrados',
              data: quantidadeCadastroMensal,
              backgroundColor: '#8d9afc',
              lineTension: 0.1,

              borderColor: '#5B65C8',


              borderWidth: 4
            },
            {
              label: 'Prontos',
              data: quantidadeMensalPronto,
              backgroundColor: "#7dd1ce",
              lineTension: 0.1,

              borderColor: '#00D0CB',


              borderWidth: 4
            },
          ],


        },

        options: {
          title: {
            display: true,
            fontSize: 16,
            fontFamily: 'Helvetica, Arial, sans-serif',
            text: "",
            position: 'top',
            border: '1',
          },

          legend: {
            display: false,
            position: "bottom",
          },
          scales: {
            yAxes: [{
              type: 'linear',
              ticks: {
                beginAtZero: true
              },
              gridLines: {
                drawOnChartArea: true,
              }
            }]
          },
        }



      },
      totalProtocolosMensal: { dados: [totalCadastroMensal, totalProntoMensal] }
    })
  }



  getNaturezaFormalData(descricaoCadastro, quantidadeCadastro, totalCadastro,
                        descricaoPronto, quantidadePronto, totalPronto,
                        descricaoGeral,quantidadeCadastradoGeral,quantidadeProntoGeral) {
    this.setState({
      protocolo: {

        chartDataPronto: {
          labels: descricaoPronto,
          datasets: [
            {
              label: 'Prontos',
              data: quantidadePronto,
              backgroundColor: '#00D0CB',


              borderColor: '#5B65C8',


              borderWidth: 1
            },
          ]
        },
        chartDataCadastro: {
          labels: descricaoCadastro,
          datasets: [
            {
              label: 'Cadastrados',
              data: quantidadeCadastro,
              backgroundColor: '#5B65C8',


              borderColor: '#00D0CB',


              borderWidth: 1
            },
          ]
        },
        chartDataGeral:{
          labels: descricaoGeral,
          datasets: [
            {
              label: 'Prontos',
              data: quantidadeProntoGeral,
              backgroundColor: '#00D0CB',


              borderColor: '#5B65C8',


              borderWidth: 1
            },
            {
            label: 'Cadastrados',
            data: quantidadeCadastradoGeral,
            backgroundColor: '#5B65C8',

            borderColor: '#00D0CB',


            borderWidth: 1
          },
          ]
        },
        options: {

          legend: {
            display: true,
            position: "top",
            text: "AAAAAAA"
          },
          scales: {
            yAxes: [{
              type: 'linear',
              ticks: {
                beginAtZero: true
              },
              gridLines: {
                drawOnChartArea: true,
              }
            }]
          },
        }


      },
      totalProtocolos: { dados: [totalCadastro, totalPronto] },

    });
  }

  getTotalStatus(quantidadeStatus){
    this.setState({
      protocoloStatusTotal:{
        chartDataStatus:{
          labels: ["VIGENTE","CANCELADO","REGISTRADO","PROCESSO DE DÚVIDA","OUTROS","DILIGENCIADO"],
          datasets: [
            {
              label: 'Prontos',
              data: quantidadeStatus,
              backgroundColor: [coresGraficos.linha[0],coresGraficos.linha[1],coresGraficos.linha[2],coresGraficos.linha[3],coresGraficos.linha[4],coresGraficos.linha[5]],


              borderColor: 'transparent',


              borderWidth: 1
            },
          ]
        },
        options:{}
      }
    })
  }

  async contadorProgressivo(){
    await timeout(2000)
    var num = 100
    for (let i = 0; i <= num; i=i+(num/10)) {
      this.setState({
        contador: i
      })
      await timeout(1);
    };    
}

  formataData(state, data) {

    const dd = data.split("-");

    const dataPronta = dd[2] + '/' + dd[1] + '/' + dd[0];
    //const dataFormatada = format(data, 'dd/MM/YYY');
    this.setState({ [state]: dataPronta });

  }

  formataDataPeriodo(data) {
    const dd = data.split("/");
    const dataPronta = dd[2] + '-' + dd[1] + '-' + dd[0];
    return dataPronta;
  }

  formataDataFiltro() {
    const dataHoje = new Date();
    const data = format(dataHoje, 'YYY-MM-dd');
    return data;
  }


  filtraDia = e => {
    const dataFim = this.formataDataFiltro();
    this.enviaFiltragem(dataFim, dataFim);
    this.enviaFiltragemTotal(dataFim, dataFim);
    this.enviaFiltragemMensal(dataFim, dataFim);
    this.formataData('dataInicioFormatada', dataFim);
    this.formataData('dataFimFormatada', dataFim);
    this.setState({ title: "MOVIMENTAÇÃO DIÁRIA" })
  }

  filtraSemana = async e => {
    const dataHoje = new Date();
    const diaSemana = dataHoje.getDay()
   
    const dataFim = format(dataHoje, 'YYY-MM-dd');
    const dataConvertida = addDays(dataHoje, -diaSemana);
    const dataInicio = format(dataConvertida, 'YYY-MM-dd');

    this.enviaFiltragem(dataInicio, dataFim);
    this.enviaFiltragemTotal(dataInicio, dataFim);
    this.enviaFiltragemMensal(dataInicio, dataFim);
    this.formataData('dataInicioFormatada', dataInicio);
    this.formataData('dataFimFormatada', dataFim);


  }

  filtraMes = async () => {
    var dataAtual = new Date();
    const mes = dataAtual.getMonth()
    const ano = dataAtual.getFullYear()
    var ultimoDiaMes
    while (dataAtual.getMonth() === mes) {

      ultimoDiaMes = dataAtual.getDate();

      dataAtual.setDate(dataAtual.getDate() + 1);
    }


    var dataInicio0 = new Date(ano, mes, 1);
    var dataFim0 = new Date(ano, mes, ultimoDiaMes);

    const dataInicio = format(dataInicio0, 'yyyy-MM-dd');
    const dataFim = format(dataFim0, 'yyyy-MM-dd');


    this.enviaFiltragem(dataInicio, dataFim);
    this.enviaFiltragemTotal(dataInicio, dataFim);
    this.enviaFiltragemMensal(dataInicio, dataFim);
    this.formataData('dataInicioFormatada', dataInicio);
    this.formataData('dataFimFormatada', dataFim);
    this.setState({ dataInicio: dataInicio })
    this.setState({ dataFim: dataFim })
  }

  filtraPeriodo = (value, dateString) => {

    

    const dataHoje = new Date();
    const data = format(dataHoje, 'dd/MM/YYY');

    const inicio = dateString[0] ? dateString[0] : data;

    const fim = dateString[1] ? dateString[1] : data;
    const dataInicio = this.formataDataPeriodo(inicio);


    const dataFim = this.formataDataPeriodo(fim);

    
    this.enviaFiltragemMensal(dataInicio, dataFim);
    this.enviaFiltragemTotal(dataInicio, dataFim);
    this.enviaFiltragem(dataInicio, dataFim);
    this.setState({ dataInicioFormatada: inicio });
    this.setState({ dataFimFormatada: fim });


    this.setState({ title: "MOVIMENTAÇÃO DE PERÍODO PERSONALIZADO" })
  }



  getMes = async mesString => {
    var mes
    var nmrMes
    switch (mesString) {
      case "Janeiro":
        mes = "01"
        nmrMes = 0
        break;
      case "Fevereiro":
        mes = "02"
        nmrMes = 1
        break;
      case "Março":
        mes = "03"
        nmrMes = 2
        break;
      case "Abril":
        mes = "04"
        nmrMes = 3
        break;
      case "Maio":
        mes = "05"
        nmrMes = 4
        break;
      case "Junho":
        mes = "06"
        nmrMes = 5
        break;
      case "Julho":
        mes = "07"
        nmrMes = 6
        break;
      case "Agosto":
        mes = "08"
        nmrMes = 7
        break;
      case "Setembro":
        mes = "09"
        nmrMes = 8
        break;
      case "Outubro":
        mes = "10"
        nmrMes = 9
        break;
      case "Novembro":
        mes = "11"
        nmrMes = 10
        break;
      case "Dezembro":
        mes = "12"
        nmrMes = 11
        break;

      default:
        mes = "Janeiro"
        nmrMes = "01"
        break;
    }
    await this.setState({ mesQtde: mes })

    this.enviaDadosMensal(this.state.mesQtde, this.state.anoQtde);

  }

  getAno = async ano => {

    await this.setState({ anoQtde: ano })
    this.enviaDadosMensal(this.state.mesQtde, this.state.anoQtde);
  }

  getGrafico = async tipoGrafico => {
    if (tipoGrafico === "Linha") {

      await this.setState({ tipoGrafico: false })

    } else {

      await this.setState({ tipoGrafico: true })
      await this.setState({
        chartDataMensal: {
          datasets: {
            backgroundColor: "#000000"
          }
        }
      })
    }
  }

  enviaFiltragemTotal = async (dataInicio, dataFim) => {
    this.setState({ totalProtocolosProgressBar: { dados: [0, 0, 0] } })
    await timeout(600);
   
    const protocoloDadosCadastro = await axios.get(`${this.state.URL_SRI}/api/sri/relatorio/protocolo/status/quantidade/dataCadastro?filtro={"inicio":"${dataInicio}T00:00:00.000","fim":"${dataFim}T23:59:59.599"}`,
   this.state.SRI_HEADER
     )
    const protocoloDadosPronto = await axios.get(`${this.state.URL_SRI}/api/sri/relatorio/protocolo/dias/dataPronto?filtro={"inicio":"${dataInicio}T00:00:00.000","fim":"${dataFim}T23:59:59.599"}`, 
   
    this.state.SRI_HEADER
    )
   
    const totalProtocolosCadastro = protocoloDadosCadastro.data[6].quantidade;
    const totalProtocolosPronto = protocoloDadosPronto.data[0].total;
    console.log(protocoloDadosPronto.data)

    if (totalProtocolosCadastro > 0) {
      this.setState({ totalProtocolosProgressBar: { dados: [totalProtocolosCadastro, totalProtocolosPronto, 100] } })
    } else {
      this.setState({ totalProtocolosProgressBar: { dados: [totalProtocolosCadastro, totalProtocolosPronto, 0] } })
    }

    let quantidadeStatus = []
    
    for (let i = 0; i < 6; i++) {
      
      quantidadeStatus.push(protocoloDadosCadastro.data[i].quantidade)
      
    }
   this.getTotalStatus(quantidadeStatus)

  }


  enviaFiltragemMensal = async (dataInicio, dataFim) => {
    await timeout(600);

    const ProtocoloCadastroMensal = await axios.get(`${this.state.URL_SRI}/api/sri/relatorio/protocolo/dias/dataCadastro?filtro={"inicio":"${dataInicio}T00:00:00.000","fim":"${dataFim}T23:59:59.599"}`, 
    this.state.SRI_HEADER
    )
    const ProtocoloProntoMensal = await  axios.get(`${this.state.URL_SRI}/api/sri/relatorio/protocolo/dias/dataPronto?filtro={"inicio":"${dataInicio}T00:00:00.000","fim":"${dataFim}T23:59:59.599"}`,
    this.state.SRI_HEADER
     )


    const dadosProtocoloCadastroMensal = ProtocoloCadastroMensal.data
    const dadosProtocoloProntoMensal = ProtocoloProntoMensal.data

    var listaDias = []
    for (let i = 1; i <= 31; i++) {
      listaDias.push(i)
    }

    let quantidadeMensalCadastrado = [
      dadosProtocoloCadastroMensal[0].um, dadosProtocoloCadastroMensal[0].dois, dadosProtocoloCadastroMensal[0].tres,
      dadosProtocoloCadastroMensal[0].quatro, dadosProtocoloCadastroMensal[0].cinco, dadosProtocoloCadastroMensal[0].seis,
      dadosProtocoloCadastroMensal[0].sete, dadosProtocoloCadastroMensal[0].oito, dadosProtocoloCadastroMensal[0].nove,
      dadosProtocoloCadastroMensal[0].dez, dadosProtocoloCadastroMensal[0].onze, dadosProtocoloCadastroMensal[0].doze,
      dadosProtocoloCadastroMensal[0].treze, dadosProtocoloCadastroMensal[0].quatorze, dadosProtocoloCadastroMensal[0].quinze,
      dadosProtocoloCadastroMensal[0].dezesseis, dadosProtocoloCadastroMensal[0].dezessete, dadosProtocoloCadastroMensal[0].dezoito,
      dadosProtocoloCadastroMensal[0].dezenove, dadosProtocoloCadastroMensal[0].vinte, dadosProtocoloCadastroMensal[0].vinteUm,
      dadosProtocoloCadastroMensal[0].vinteDois, dadosProtocoloCadastroMensal[0].vinteTres, dadosProtocoloCadastroMensal[0].vinteQuatro,
      dadosProtocoloCadastroMensal[0].vinteCinco, dadosProtocoloCadastroMensal[0].vinteSeis, dadosProtocoloCadastroMensal[0].vinteSete,
      dadosProtocoloCadastroMensal[0].vinteOito, dadosProtocoloCadastroMensal[0].vinteNove, dadosProtocoloCadastroMensal[0].trinta,
      dadosProtocoloCadastroMensal[0].trintaUm,
    ]

    let quantidadeMensalPronto = [
      dadosProtocoloProntoMensal[0].um, dadosProtocoloProntoMensal[0].dois, dadosProtocoloProntoMensal[0].tres,
      dadosProtocoloProntoMensal[0].quatro, dadosProtocoloProntoMensal[0].cinco, dadosProtocoloProntoMensal[0].seis,
      dadosProtocoloProntoMensal[0].sete, dadosProtocoloProntoMensal[0].oito, dadosProtocoloProntoMensal[0].nove,
      dadosProtocoloProntoMensal[0].dez, dadosProtocoloProntoMensal[0].onze, dadosProtocoloProntoMensal[0].doze,
      dadosProtocoloProntoMensal[0].treze, dadosProtocoloProntoMensal[0].quatorze, dadosProtocoloProntoMensal[0].quinze,
      dadosProtocoloProntoMensal[0].dezesseis, dadosProtocoloProntoMensal[0].dezessete, dadosProtocoloProntoMensal[0].dezoito,
      dadosProtocoloProntoMensal[0].dezenove, dadosProtocoloProntoMensal[0].vinte, dadosProtocoloProntoMensal[0].vinteUm,
      dadosProtocoloProntoMensal[0].vinteDois, dadosProtocoloProntoMensal[0].vinteTres, dadosProtocoloProntoMensal[0].vinteQuatro,
      dadosProtocoloProntoMensal[0].vinteCinco, dadosProtocoloProntoMensal[0].vinteSeis, dadosProtocoloProntoMensal[0].vinteSete,
      dadosProtocoloProntoMensal[0].vinteOito, dadosProtocoloProntoMensal[0].vinteNove, dadosProtocoloProntoMensal[0].trinta,
      dadosProtocoloProntoMensal[0].trintaUm,
    ]

    var totalCadastroMensal = quantidadeMensalCadastrado.reduce(function (totalCadastroMensal, quantidadeMensalCadastrado) {
      return totalCadastroMensal + quantidadeMensalCadastrado;
    }, 0);
    var totalProntoMensal = quantidadeMensalPronto.reduce(function (totalCadastroMensal, quantidadeMensalPronto) {
      return totalCadastroMensal + quantidadeMensalPronto;
    }, 0);


    this.getDiasMensalData(listaDias, quantidadeMensalCadastrado, totalCadastroMensal,
      quantidadeMensalPronto, totalProntoMensal
    );


  }





  enviaDadosMensal = async (mes, ano) => {
    
    var ultimoDiaMes

    var auxData = new Date(`${ano}-${mes}-1`)

    while (auxData.getMonth() === Number(mes) - 1) {
      ultimoDiaMes = auxData.getDate();
      auxData.setDate(auxData.getDate() + 1);
    }
    await timeout(600);
    const ProtocoloCadastroMensal = await axios.get(`${this.state.URL_SRI}/api/sri/relatorio/protocolo/dias/dataCadastro?filtro={"inicio":"${ano}-${mes}-01T00:00:00.000","fim":"${ano}-${mes}-${ultimoDiaMes}T23:59:59.599"}`, 
    this.state.SRI_HEADER
    )
    const ProtocoloProntoMensal = await axios.get(`${this.state.URL_SRI}/api/sri/relatorio/protocolo/dias/dataPronto?filtro={"inicio":"${ano}-${mes}-01T00:00:00.000","fim":"${ano}-${mes}-${ultimoDiaMes}T23:59:59.599"}`,
    this.state.SRI_HEADER
     )

    const dadosProtocoloCadastroMensal = ProtocoloCadastroMensal.data
    const dadosProtocoloProntoMensal = ProtocoloProntoMensal.data

    let diasMes = []
    for (let i = 1; i <= ultimoDiaMes; i++) {
      diasMes.push(i + `/${mes}`)

    }

    let quantidadeMensalCadastrado = [
      dadosProtocoloCadastroMensal[0].um, dadosProtocoloCadastroMensal[0].dois, dadosProtocoloCadastroMensal[0].tres,
      dadosProtocoloCadastroMensal[0].quatro, dadosProtocoloCadastroMensal[0].cinco, dadosProtocoloCadastroMensal[0].seis,
      dadosProtocoloCadastroMensal[0].sete, dadosProtocoloCadastroMensal[0].oito, dadosProtocoloCadastroMensal[0].nove,
      dadosProtocoloCadastroMensal[0].dez, dadosProtocoloCadastroMensal[0].onze, dadosProtocoloCadastroMensal[0].doze,
      dadosProtocoloCadastroMensal[0].treze, dadosProtocoloCadastroMensal[0].quatorze, dadosProtocoloCadastroMensal[0].quinze,
      dadosProtocoloCadastroMensal[0].dezesseis, dadosProtocoloCadastroMensal[0].dezessete, dadosProtocoloCadastroMensal[0].dezoito,
      dadosProtocoloCadastroMensal[0].dezenove, dadosProtocoloCadastroMensal[0].vinte, dadosProtocoloCadastroMensal[0].vinteUm,
      dadosProtocoloCadastroMensal[0].vinteDois, dadosProtocoloCadastroMensal[0].vinteTres, dadosProtocoloCadastroMensal[0].vinteQuatro,
      dadosProtocoloCadastroMensal[0].vinteCinco, dadosProtocoloCadastroMensal[0].vinteSeis, dadosProtocoloCadastroMensal[0].vinteSete,
      dadosProtocoloCadastroMensal[0].vinteOito, dadosProtocoloCadastroMensal[0].vinteNove, dadosProtocoloCadastroMensal[0].trinta,
      dadosProtocoloCadastroMensal[0].trintaUm,
    ]

    let quantidadeMensalPronto = [
      dadosProtocoloProntoMensal[0].um, dadosProtocoloProntoMensal[0].dois, dadosProtocoloProntoMensal[0].tres,
      dadosProtocoloProntoMensal[0].quatro, dadosProtocoloProntoMensal[0].cinco, dadosProtocoloProntoMensal[0].seis,
      dadosProtocoloProntoMensal[0].sete, dadosProtocoloProntoMensal[0].oito, dadosProtocoloProntoMensal[0].nove,
      dadosProtocoloProntoMensal[0].dez, dadosProtocoloProntoMensal[0].onze, dadosProtocoloProntoMensal[0].doze,
      dadosProtocoloProntoMensal[0].treze, dadosProtocoloProntoMensal[0].quatorze, dadosProtocoloProntoMensal[0].quinze,
      dadosProtocoloProntoMensal[0].dezesseis, dadosProtocoloProntoMensal[0].dezessete, dadosProtocoloProntoMensal[0].dezoito,
      dadosProtocoloProntoMensal[0].dezenove, dadosProtocoloProntoMensal[0].vinte, dadosProtocoloProntoMensal[0].vinteUm,
      dadosProtocoloProntoMensal[0].vinteDois, dadosProtocoloProntoMensal[0].vinteTres, dadosProtocoloProntoMensal[0].vinteQuatro,
      dadosProtocoloProntoMensal[0].vinteCinco, dadosProtocoloProntoMensal[0].vinteSeis, dadosProtocoloProntoMensal[0].vinteSete,
      dadosProtocoloProntoMensal[0].vinteOito, dadosProtocoloProntoMensal[0].vinteNove, dadosProtocoloProntoMensal[0].trinta,
      dadosProtocoloProntoMensal[0].trintaUm,
    ]

    var totalCadastroMensal = quantidadeMensalCadastrado.reduce(function (totalCadastroMensal, quantidadeMensalCadastrado) {
      return totalCadastroMensal + quantidadeMensalCadastrado;
    }, 0);
    var totalProntoMensal = quantidadeMensalPronto.reduce(function (totalCadastroMensal, quantidadeMensalPronto) {
      return totalCadastroMensal + quantidadeMensalPronto;
    }, 0);


    this.getDiasMensalData(diasMes, quantidadeMensalCadastrado, totalCadastroMensal,
      quantidadeMensalPronto, totalProntoMensal
    );

  }

  enviaFiltragem = async (dataInicio, dataFim) => {
    this.setState({ loading: true });

    //const ip = localStorage.getItem('MG_IP');

    let totalCadastro;
    let totalPronto;

    // Ajax calls here
    //REQUISIÇÃO DE DADOS DATACADASTRO
    await timeout(600);
    const ProtocoloCadastroPeriodo = await axios.get(`${this.state.URL_SRI}/api/sri/relatorio/protocolo/naturezaformal/dataCadastro?filtro={"inicio":"${dataInicio}T00:00:00.000", "fim":"${dataFim}T23:59:59.599"}&=`, 
    this.state.SRI_HEADER
    )
    const ProtocoloProntoPeriodo = await axios.get(`${this.state.URL_SRI}/api/sri/relatorio/protocolo/naturezaformal/dataPronto?filtro={"inicio":"${dataInicio}T00:00:00.000", "fim":"${dataFim}T23:59:59.599"}&=`,
    this.state.SRI_HEADER
     )

    const dadosProtocoloCadastro = ProtocoloCadastroPeriodo.data
    const dadosProtocoloPronto = ProtocoloProntoPeriodo.data

    function trataDadosProtocolos(dadosCadastro, dadosPronto) {

      let descricaoCadastro = [];
      let quantidadeCadastro = [];
      let descricaoPronto = [];
      let quantidadePronto = [];

      let auxProntos = []
      let auxCadastros = []

      let listaDescricao = [];
      let listaDescricao2 = [];
      let listaDescricao3 = [];
      let listaQuantidade = [];
      let listaQuantidade2 = [];
      let listaQuantidade3 = [];
      let listaQuantidadeMenor = [];
      let listaQuantidadeMenor2 = [];
      let listaQuantidadeMenor3 = [];

      var dataCad
      var dataCad2
      for (const dataObj of dadosCadastro) {
        auxCadastros.push((dataObj))
      }

      auxCadastros.sort(function (a, b) {
        return a.quantidade > b.quantidade ? -1 :
          (a.quantidade < b.quantidade ? 1 : 0);

      });


      for (const dataObj of dadosPronto) {
        auxProntos.push((dataObj))
      }
      auxProntos.sort(function (a, b) {
        return a.quantidade > b.quantidade ? -1 :
          (a.quantidade < b.quantidade ? 1 : 0);
      });


      for (dataCad of auxCadastros) {

        if (dataCad.descricao.length > 36) {
          descricaoCadastro.push(dataCad.descricao.substr(0, 36) + "...")
          quantidadeCadastro.push(parseInt(dataCad.quantidade))
        } else {
          descricaoCadastro.push(dataCad.descricao.substr(0, 36))
          quantidadeCadastro.push(parseInt(dataCad.quantidade))
        }


      }

      for (dataCad2 of auxProntos) {

        if (dataCad2.descricao.length > 36) {
          descricaoPronto.push(dataCad2.descricao.substr(0, 36) + "...")
          quantidadePronto.push(parseInt(dataCad2.quantidade))
        } else {
          descricaoPronto.push(dataCad2.descricao.substr(0, 36))
          quantidadePronto.push(parseInt(dataCad2.quantidade))
        }
      }
      totalCadastro = quantidadeCadastro.reduce(function (totalCadastro, quantidade) {
        return totalCadastro + quantidade;
      }, 0);

      totalPronto = quantidadePronto.reduce(function (totalPronto, quantidade) {
        return totalPronto + quantidade;
      }, 0);


      return {
        descricaoPronto, quantidadePronto, totalPronto,
        descricaoCadastro, quantidadeCadastro, totalCadastro

      }

    }

    let dadosCadastro = trataDadosProtocolos(dadosProtocoloCadastro, dadosProtocoloPronto)
    
    function juntaDadosCadastradosProntos(descCadastro,qtdeCadastro,descPronto,qtdePronto){
      

      var maior = descCadastro.length >= descPronto.length?{desc: descCadastro,qtde: qtdeCadastro, target: "maior-cad"}:{desc: descPronto,qtde: qtdePronto,target: "maior-pronto"};
      var menor = descCadastro.length >= descPronto.length?{desc: descPronto,qtde: qtdePronto}:{desc: descCadastro,qtde: qtdeCadastro};

      let DescList = [];
      var QtdeMaior = [];
      var QtdeMenor = [];

      for (const aux of maior.desc) {
		    QtdeMaior.push(maior.qtde[maior.desc.indexOf(aux)])
    
  	  if(menor.desc.includes(aux) ){
  		  QtdeMenor.push(menor.qtde[menor.desc.indexOf(aux)])
        DescList.push(aux)
  	  }
      else{
      DescList.push(aux)
    	QtdeMenor.push(0);
      }  	
}

    for (const aux2 of menor.desc) {
	
  	    if(!maior.desc.includes(aux2) ){
        DescList.push(aux2)
  		  QtdeMenor.push(menor.qtde[menor.desc.indexOf(aux2)])
        QtdeMaior.push(0);
  	}
  }
    let QtdeCadastro =[]
    let QtdePronto= []
    if(maior.target =="maior-cad"){
      QtdeCadastro = QtdeMaior;
      QtdePronto= QtdeMenor;
    }else if(maior.target =="maior-pronto"){
     QtdePronto = QtdeMaior;
     QtdeCadastro= QtdeMenor;
    }  	
  
  return{
    DescList, QtdePronto,  QtdeCadastro
  }
    }
    let dadosGerais = juntaDadosCadastradosProntos(dadosCadastro.descricaoCadastro, dadosCadastro.quantidadeCadastro, 
                                 dadosCadastro.descricaoPronto, dadosCadastro.quantidadePronto )

  

    this.getNaturezaFormalData(dadosCadastro.descricaoCadastro, dadosCadastro.quantidadeCadastro, dadosCadastro.totalCadastro,
                                dadosCadastro.descricaoPronto, dadosCadastro.quantidadePronto, dadosCadastro.totalPronto,
                            dadosGerais.DescList, dadosGerais.QtdeCadastro,dadosGerais.QtdePronto
                                );

    this.setState({ loading: false });
  }

  onTabChange = (key, type) => {

    this.setState({ [type]: key });
  };



  render() {
    const { RangePicker } = DatePicker;
    const { Title, Paragraph } = Typography;
    const { TabPane } = Tabs;
    const tabListNoTitle = [
      {
        key: 'cadastrados',
        tab: 'CADASTRADOS',
      },
      {
        key: 'prontos',
        tab: 'PRONTOS',
      },
      {
        key: 'geral',
        tab: 'GERAL',
      },

    ];

    const contentListNoTitle = {
      prontos: <>
      <Col style={{ marginTop: '10px' }} md={24} lg={24} xl={24}>

      <Col md={12} lg={12} xl={12}>
        <Title style={{ color: coresGraficos.linha[1], marginTop: '0px', marginBottom: '0px', fontWeight: 'bold', fontSize: '18px' }}>{this.state.totalProtocolos.dados[1]}</Title>
        <Paragraph style={{ marginBottom: '0px', fontSize: '14px' }}>Total Prontos</Paragraph>
      </Col>

      </Col>
        <Col md={24}>
          <div style={{
            alignItems: "center",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "center",
          }}>
            {this.state.loading ?
              <Loader />
              : null}
            <HorizontalBar width={100} 
            // height={35}
              data={this.state.protocolo.chartDataPronto}
              options={{
                legend: {
                display: false,
                position: "bottom",
              }
             
              
            }
          }
            />
          </div>



        </Col>
        
      </>,
      cadastrados: <>
      <Col md={24}>
      <Col style={{ marginTop: '10px' }} md={24} lg={24} xl={24}>

        <Col md={12} lg={12} xl={12}>
              <Title style={{ color: coresGraficos.linha[0], marginTop: '0px', marginBottom: '0px', fontWeight: 'bold', fontSize: '18px' }}>{this.state.totalProtocolos.dados[0]}</Title>
              <Paragraph style={{ marginBottom: '0px', fontSize: '14px' }}>Total Cadastrados</Paragraph>
        </Col>

      </Col>
          <HorizontalBar width={100} 
            // height={35}
              data={this.state.protocolo.chartDataCadastro}
              options={{
                legend: {
                display: false,
                position: "bottom",
              }
            }
          }
            />
      </Col>
      </>,
      geral:
      <>
      <Col md={24}>
      <Col style={{ marginTop: '10px' }} md={24} lg={24} xl={24}>

      </Col>
          <HorizontalBar width={100} 
              data={this.state.protocolo.chartDataGeral}
              options={{
                legend: {
                display: false,
                position: "bottom",
              }
            }
          }
            />
      </Col>
      </>,

    };


    return (
      <div >
        
        <Row gutter={24} type="flex" justify="space-between">
          <Col style={{display: "flex"}} justify="space-around" md={8} lg={8} xl={8}>
            <Title style={{ fontWeight: 'bold' }}>PROTOCOLOS </Title>
            
          </Col>
          <Col md={8} lg={8} xl={8} style={{ marginBottom: '10px', textAlign: 'center' }}>
          {this.state.loading ?
              <Loader style={{display: "flex"}}/>
              : ""}
           </Col> 
          <Col md={8} lg={8} xl={8} style={{ marginBottom: '10px', textAlign: 'right' }}>
            <Title style={{ fontWeight: 'bold' }} level={3}>{`Período: ${this.state.dataInicioFormatada} a ${this.state.dataFimFormatada}`}</Title>
          </Col>
        </Row>
        <Row gutter={24} type="flex" justify="end">

        </Row>

        <Row gutter={24} width={100}>
          <Col md={24}>

            <Row gutter={24}>
              <Col md={24} lg={24} xl={12}>
                <Card
                  size="small"  bodyStyle={{ padding: "23px 10%", }} style={{ marginTop: '20px', backgroundColor: "#f9f9f9", borderRadius: '15px' }}
                  title={
                    <Row gutter={24} type="flex" justify='space-around' >
                      {/* <Col md={4} lg={4} xl={4} >
                        <Title style={{ textAlign: 'left' }} level={4}></Title>
                      </Col> */}
                      <Col md={20} lg={20} xl={20} >
                        <RangePicker style={{ width: 250 }}
                          placeholder={['Período']}
                          //onOk={this.onOk}
                          onChange={this.filtraPeriodo}
                          // defaultValue={[moment('YYYY-MM-DDT00:00:00.000'), moment('YYYY-MM-DDT00:00:00.000')]}
                          format={'DD/MM/YYYY'}
                        />{'  '}
                        <Radio.Group value={14} >
                          <Radio.Button checked={this.state.checked} onClick={() => this.filtraDia()} value="large">HOJE</Radio.Button>
                          <Radio.Button onClick={() => this.filtraSemana()} value="default">SEMANA</Radio.Button>
                          <Radio.Button onClick={() => this.filtraMes()} value="small">MÊS</Radio.Button>

                        </Radio.Group>
                      </Col>
                    </Row>
                  }
                >
                  <Row type="flex" justify="space-between">
                    <div style={{ textAlign: "center", width: "40%", height: "auto" }}>

                      <span style={{ marginTop: "100px" }}><h3 style={{ color: coresGraficos.linha[0] }}><strong> CADASTRADOS</strong></h3></span>
                      <VisibilitySensor>
                        {({ isVisible }) => {
                          if (isVisible == true) {
                            var percentage = this.state.totalProtocolosProgressBar.dados[2];
                          } else {
                            var percentage = 0;
                          }
                          //const percentage = isVisible ? this.state.totalProtocolosProgressBar.dados[2] : 0;

                          return (
                            <CircularProgressbar
                              value={this.state.totalProtocolosProgressBar.dados[2]}
                              text={`${this.state.totalProtocolosProgressBar.dados[0]}`}
                              styles={buildStyles({
                                //  Rotação do caminho e trilha, em número de voltas (0-1)
                                rotação: 0.25,

                                //  Se deve usar cantos arredondados ou planos nas pontas - pode usar 'butt' ou 'redondo'
                                strokeLinecap: 'butt',

                                //  Tamanho do texto
                                textSize: '16px',

                                //  Quanto tempo a animação leva para ir de uma porcentagem a outra, em segundos
                                pathTransitionDuration: 1,

                                //  Pode especificar a transição do caminho com mais detalhes ou removê-la inteiramente
                                //  pathTransition: 'none',

                                //  Colors
                                //rgba(62, 152, 199, ${percentage/100})
                                pathColor: coresGraficos.linha[0],
                                textColor: coresGraficos.linha[0],
                                trailColor: '#d6d6d6',
                                backgroundColor: '#f88',
                              })}
                            />
                          );
                        }}
                      </VisibilitySensor>
                    </div>

                    <div style={{ textAlign: "center", width: "40%", height: "auto" }}>

                      <span style={{ marginTop: "100px" }}><h3 style={{ color: coresGraficos.linha[1] }}><strong> PRONTOS</strong></h3></span>
                      <VisibilitySensor>
                        {({ isVisible }) => {
                          if (isVisible == true) {
                            var percentage = this.state.totalProtocolosProgressBar.dados[2];
                          } else {
                            var percentage = 0;
                          }
                          //const percentage = isVisible ? this.state.totalProtocolosProgressBar.dados[2] : 0;

                          return (
                            <CircularProgressbar
                              value={this.state.totalProtocolosProgressBar.dados[2]}
                              text={`${this.state.totalProtocolosProgressBar.dados[1]}`}
                              styles={buildStyles({
                                //  Rotação do caminho e trilha, em número de voltas (0-1)
                                rotação: 0.25,

                                //  Se deve usar cantos arredondados ou planos nas pontas - pode usar 'butt' ou 'redondo'
                                strokeLinecap: 'butt',

                                //  Tamanho do texto
                                textSize: '16px',

                                //  Quanto tempo a animação leva para ir de uma porcentagem a outra, em segundos
                                pathTransitionDuration: 1,

                                //  Pode especificar a transição do caminho com mais detalhes ou removê-la inteiramente
                                //  pathTransition: 'none',

                                //  Colors
                                //rgba(62, 152, 199, ${percentage/100})
                                pathColor: coresGraficos.linha[1],
                                textColor: coresGraficos.linha[1],
                                trailColor: '#d6d6d6',
                                backgroundColor: '#f88',
                              })}
                            />
                          );
                        }}
                      </VisibilitySensor>
                    </div>
                  </Row>
                  <Row type="flex" justify="space-between">
                  <Pizza periodo={this.state.protocoloStatusTotal.chartDataStatus} 
                  options={this.state.protocoloStatusTotal.options}/>
                  </Row>

                </Card>
              </Col>
              <Col md={24} lg={24} xl={12}>
                <Card size="small" style={{ marginTop: '20px', backgroundColor: "#f9f9f9",borderRadius: '15px' }}

                  title={
                    <Row type="flex" justify="space-between" >
                      <div>
                        <b style={{ marginRight: '5px' }}>Tipo de gráfico: </b>
                        <Select defaultValue={`Linha`} style={{ width: 80 }} onChange={this.getGrafico}>
                          <Option value="Linha">Linhas</Option>
                          <Option value="Barra">Barras</Option>
                        </Select>
                      </div>
                      {/* <div><h2><strong>Protocolos Cadastrados e Prontos Por Dia</strong></h2></div> */}

                    </Row>
                  }>
                  <Col md={24}>
                    {this.state.tipoGrafico ?
                      <Barra width={100} height={35}
                        periodo={this.state.protocoloMensal.chartDataMensalBarra}
                        options={this.state.protocoloMensal.options} />
                      : <Linha width={100} height={35}
                        periodo={this.state.protocoloMensal.chartDataMensalLinha}
                        options={this.state.protocoloMensal.options} />}

                  </Col>
                  <Col style={{ marginTop: '10px' }} md={24} lg={24} xl={24}>

                    <Col md={12} lg={12} xl={12}>
                      <Title style={{ color: coresGraficos.linha[0], marginTop: '0px', marginBottom: '0px', fontWeight: 'bold', fontSize: '18px' }}> {this.state.totalProtocolosMensal.dados[0]} </Title>
                      <Paragraph style={{ marginBottom: '0px', fontSize: '14px' }}>Total Cadastrados</Paragraph>
                    </Col>
                    <Col md={12} lg={12} xl={12}>
                      <Title style={{ color: coresGraficos.linha[1], marginTop: '0px', marginBottom: '0px', fontWeight: 'bold', fontSize: '18px' }}> {this.state.totalProtocolosMensal.dados[1]}  </Title>
                      <Paragraph style={{ marginBottom: '0px', fontSize: '14px' }}>Total Pronto</Paragraph>
                    </Col>
                  </Col>
                </Card>
              </Col>
            </Row>


            <Card
              size="small" bodyStyle={{ padding: "0" }} style={{ marginTop: '20px', backgroundColor: "#f9f9f9", borderRadius: '15px' }}
              title={
                <Row gutter={24} type="flex" justify="space-between" style={{ marginTop: '20px' }}>
                  <Col md={8} lg={8} xl={8} >
                    <Title style={{ textAlign: 'center' }} level={4}>Natureza Formal</Title>
                  </Col>
                  <Col md={8} lg={8} xl={8} >
                  <div style={{display:"flex",textAlign: "center", justifyContent: "center", alignItems: "center"}}>
                    <div className="divTotais" style={{border:`3px solid ${coresGraficos.linha[0]}`}}>
                      <Paragraph style={{fontWeight: 'bold', background:coresGraficos.linha[0],color:'white',marginBottom: '0px', fontSize: '14px', bordeRadius: "12px 0 0 12px"}}>CADASTRADOS:</Paragraph>    
                      <Title style={{color:coresGraficos.linha[0],marginTop: '0px', marginBottom: '0px', fontWeight: 'bold', fontSize: '40px' }}>{this.state.totalProtocolos.dados[0]}</Title>
                   </div>
              
                  <div className="divTotais" style={{border:`3px solid ${coresGraficos.linha[1]}`}}>
                    <Paragraph style={{ fontWeight: 'bold',background:coresGraficos.linha[1],color:'white',marginBottom: '0px', fontSize: '14px', bordeRadius: "12px 0 0 12px"}}>PRONTOS:</Paragraph>
                    <Title style={{color:coresGraficos.linha[1],marginTop: '0px', marginBottom: '0px', fontWeight: 'bold', fontSize: '40px' }}>{this.state.totalProtocolos.dados[1]}</Title>
                  </div>
                </div>
                  </Col>
                  <Col md={8} lg={8} xl={8} >
                    <Title style={{ textAlign: 'center' }} level={4}>{`Período: ${this.state.dataInicioFormatada} a ${this.state.dataFimFormatada}`}</Title>
                  </Col>
                </Row>
              }
            >

              <Card size="small"
                style={{ textAlign: "center", color:"#000000", borderRadius: '15px'}}
                // tabList={tabListNoTitle}
                // activeTabKey={this.state.key}
                
                // onTabChange={key => {
                //   this.onTabChange(key, 'key');

                // }}

              >
                {/* {contentListNoTitle[this.state.key]} */}
                <Col md={24}>
              <Col style={{ marginTop: '10px' }} md={24} lg={24} xl={24}> </Col>
              <HorizontalBar width={100}  data={this.state.protocolo.chartDataGeral}
              options={{
                legend: {
                display: false,
                position: "bottom",
              }
            }
          }
            />
               </Col>
              </Card>
            </Card>
            
          </Col>
        </Row>
      </div>
    );
  }
}
