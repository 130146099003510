import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { isAutenticated } from '../auth';
import Login from '../pages/Login';
import Dashboard from '../pages/Dashboard';
import Atendimento from '../pages/Atendimento';
import Atendente from '../pages/Atendente';
import Certidao from '../pages/Certidao';
import Protocolo from '../pages/Protocolo';
import Relatorio from '../pages/Relatorios';
import RelatorioAtendimento from '../pages/Relatorios/Atendimento';
import Pedidos from '../pages/Pedidos';
import Financeiro from '../pages/Financeiro';
import Teste1 from '../pages/Teste/index.js';
import Teste2 from '../pages/Teste/teste.js';
import Auth from '../pages/Auth';

//import If from '../components/if';

const PrivateRouter = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        isAutenticated() ? (
            <Component {...props} />
        ) : (
            <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )
    )} />
);

export default function Routes() {
    return (

        <Switch>
            <Route path="/auth" exact component={Auth} />
            <Route path="/" exact component={Login} />
            <PrivateRouter path="/dashboard" exact component={Dashboard} />
            <PrivateRouter path="/atendimento" component={Atendimento} />
            <PrivateRouter path="/atendentes" component={Atendente} />
            <PrivateRouter path="/protocolo" component={Protocolo} />
            <PrivateRouter path="/certidao" component={Certidao} />
            <PrivateRouter path="/relatorio" component={Relatorio} />
            <PrivateRouter path="/relatorioAtendimento" component={RelatorioAtendimento} />
            <PrivateRouter path="/pedidos" component={Pedidos} />
            <PrivateRouter path="/financeiro" component={Financeiro} />
            <Route path="/teste" component={Teste1} />
            <Route path="/teste2" component={Teste2} />
        </Switch>
    )
}