

import React, { useState, useEffect } from 'react';
import JwtDecode from 'jwt-decode';
import axios from 'axios';
import api from '../../services/apiLogin';
import FormData from 'form-data'
import '../../css/global.css';
import { Typography } from 'antd';
import history from '../../services/history';
import { saveToken, getToken, saveUrlSri, getUrlSri, saveTokenSri } from '../../utils/LoginManager';
import Cookies from 'universal-cookie';

import Loader from '../../components/Loader';



const Login = (props) => {
    const [mensagem, setMensagem] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const { Title } = Typography;
    const cookies = new Cookies();

    useEffect(() => {

        const token = document.location.search.split("?token=")[1];
    
        if (token) {
          const tokenDados = JwtDecode(document.location.search.split("?token=")[1]);
          
          saveToken(tokenDados);
          const token = getToken();

          const configuracao = JSON.parse(tokenDados.dadosAdicionais);
    
          localStorage.setItem('MG_IP', configuracao[0].ip);
          localStorage.setItem('MG_CLIENTE', tokenDados.name);
          localStorage.setItem('MG_ID', tokenDados.id);
             console.log("token igual");      
    
          saveUrlSri(configuracao[0].apisri)
    
    
          if (process.env.NODE_ENV == 'development') {
            console.log("HOMOLOGAÇÃO");
            var dadosLogin = { usuario: "m", senha: "1" };
    
          } else {
            console.log("PRODUÇÃO");
            //if (configuracao[0].cns == "85886") {
//              var dadosLogin = { usuario: "mactus", senha: "codes207017" };
  //          } else {
              var dadosLogin = { usuario: "mactus", senha: "codes207017" }
    //        };
          }
    
          let sriUrl = getUrlSri()
          try {

            const tokenSri = async () => await axios.post(`${sriUrl}/auth`, dadosLogin).then(res => {
                saveTokenSri(res.data.data.token);});
                
                tokenSri();

            
          } catch (err) {
            console.log("API SRI ERROR: " + err)
    
          }
    

          if (token) {
            setMensagem("Bem vindo(a)!");
            history.push('/dashboard');
        }
        }
    
      }, [cookies]);

  
   
    return (
        <div className={"authPage"}>
            <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                {isLoading ? <Loader style={{ display: "flex" }} /> : ""}
                <img src={'mactus.png'} style={{ width: "300px", height: "auto" }} />
            </div>
        </div>
    );
}

export default Login;

