import React,{useEffect, useState} from "react";
import PropTypes from "prop-types";
import './style.css'
import _ from "lodash";
import { Responsive, WidthProvider } from "react-grid-layout";
const ResponsiveReactGridLayout = WidthProvider(Responsive);

// lg: _.map(_.range(0, 4), function(item, i) {
//   var y = Math.ceil(Math.random() * 4) + 1;
//   return {
//     x: (_.random(0, 5) * 2) % 12,
//     y: Math.floor(i / 6) * y,
//     w: 2,
//     h: 2,
//     i: i.toString(),
//     static: Math.random() < 0.05
//   };
// })
const Teste2 = ()=>{
 
    const layTest = 0
    const [currentBreakpoint,setCurrentBreakpoint] = useState("lg");
    const [compactType, setCompactType] = useState(null);
    const [mounted,setMounted] = useState(false);
    const [layouts, setLayouts]= useState({});
    const [removing,setRemoving]= useState(false);

    function getFromLS() {
      let ls;
      if (global.localStorage) {
        try {
          ls = JSON.parse(global.localStorage.getItem("rgl-7")) || {};
        } catch (e) {
          /*Ignore*/
        }
      }
      
      return {
        lg: ls
      }
    }
    
    function saveToLS(key, value) {
      if (global.localStorage) {
        global.localStorage.setItem(
          "rgl-7",
          JSON.stringify(
             value
          )
        );
      }else{
        console.log("FALHA AO SALVAR")
      }
    }

    useEffect(()=>{
        setMounted(true);

        var rgl = getFromLS()
        
        setLayouts(rgl)

       

    },[])

    const generateDOM = ()=>{
        return _.map(layouts.lg, function(l, i) {
            return (
              <div key={i} className={l.static ? "static" : ""}>
                {l.static ? (
                  <span
                    className="text"
                    title="This item is static and cannot be removed or resized."
                  >
                    Static - {i}
                  </span>
                ) : (
                  <span className="text">{i}</span>
                )}
              </div>
            );
          });
    }

    const onBreakpointChange = (breakpoint) => {
        console.log("BREAKPOINTCHANGE "+breakpoint)
        setCurrentBreakpoint(breakpoint)
      }

    const addSquare = ()=>{
      console.log("ADD SQUARE")
      if(layouts.lg){
        setLayouts({lg: [...layouts.lg,{
          x: 2,
          y: 3,
          w: 2,
          static: false,
          h: 2
        }]})
      }else{
        setLayouts({
          lg: [
          {
          x: 2,
          y: 3,
          w: 2,
          static: false,
          h: 2
          }
        ]
      })
      }
      
    }

    const clear = ()=>{
      console.log("CLEAR")    
      setLayouts({})
      }
      
    const onDragStop = (array,item)=>{
      setRemoving(true)
     // var newList = layouts.lg.filter((el)=>el.i != Number(item.i));
     // console.log(newList)
      console.log(layouts)

      }

    const onDragStart = (array,item)=>{
        setRemoving(true)
    }
    const onMouseOver = ()=>{
      alert("LIXEIRA")
    }
      
    
    function onLayoutChange(layout){
        
        saveToLS("layout", layout);

    
        setLayouts( getFromLS())
      }

  

    
    return(
        <div>
        <div style={{display: "flex"}}>
           <button onClick={()=>addSquare()}> ADD SQUARE + </button>
           <button onClick={()=>clear()}> CLEAR </button>          
        </div>
        
        <ResponsiveReactGridLayout
          rowHeight = {100}
          cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
          onDragStart={onDragStart}
          onDragStop={onDragStop}
          layouts={layouts}
          onBreakpointChange={()=>onBreakpointChange}
          onLayoutChange={onLayoutChange}
          // WidthProvider option
          measureBeforeMount={false}
          // I like to have it animate on mount. If you don't, delete `useCSSTransforms` (it's default `true`)
          // and set `measureBeforeMount={true}`.
          useCSSTransforms={mounted}
          compactType={compactType}
          preventCollision={false}
        >
          {generateDOM()}
        </ResponsiveReactGridLayout>
        
      </div>
    )

}


// Teste2.defaultProps = {
//   className: "layout",
//   //rowHeight: 30,
//   onLayoutChange: function() {},
//  cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
 
// };

export default Teste2;
// Teste.defaultProps = {
//     //className: "layout",
//    // rowHeight: 30,
//    // onLayoutChange: function() {},
//    // cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
//     initialLayout: generateLayout()
//   };

// function generateLayout() {
//     return _.map(_.range(0, 1), function(item, i) {
//       var y = Math.ceil(Math.random() * 4) + 1;
//       return {
//         x: (_.random(0, 5) * 2) % 12,
//         y: Math.floor(i / 6) * y,
//         w: 2,
//         h: y,
//         i: i.toString(),
//         static: Math.random() < 0.05
//       };
//     });
//   }
  

  