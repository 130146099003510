import { getToken } from '../utils/LoginManager';

export const isAutenticated = () => {
    const token = getToken();
    if (token) {
        return token;
    }
    else {
        return false;
    }

};